<template>
    <w-icon value="CLOCK"/>
</template>

<script>
export default {
    name: 'ScheduleCallHead',
    props: {
        item: {
            type: Object,
            default: undefined
        }
    }
}
</script>

<style lang=scss>

</style>
